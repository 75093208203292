/**
 * Application entry point
 */

// Load application styles
import 'styles/index.scss';

import closeIcon from 'images/close.svg';

import lcbSmall from 'images/lcb-og.png'
import cb from 'images/christian.png';

import innovate from 'images/innovate.svg';
import elevate from 'images/elevate.svg';
import spotlight from 'images/spotlight.svg';
import favIcon from 'images/favicon.ico';
